/* ------------------------------------------------------------------------------------------------------------  */

/*                                            BRIGHTORCHID LLC                                                   */

/*   (c) 2020 BrightOrchid LLC   : this file should not be copied or transferred without written authorization   */

/*   from BrightOrchid LLC, Georgia, United States of America                                                    */

/* ------------------------------------------------------------------------------------------------------------  */

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

import './styles/index.css';

import { PanelData } from '../../models/classes/PanelData';
import { WidgetData } from '../../models/classes/WidgetData';

import PanelDataComponent from './components/PanelDataComponent';
import PanelSearchComponent from './components/PanelSearchComponent';
import PanelIframeComponent from './components/PanelIframeComponent';

import { isEmpty } from '../../utils/validation';

import { State } from '../../redux/reducers';
import __t from '../../utils/translation';

interface Props {
    widgetData: WidgetData;
    panelData: PanelData;
    widgetWidth: number;
}

const PanelContainer: React.FC<Props> = (props) => {
    const { widgetData, panelData } = props;
    const {
        Actions,
        Buttons,
        NumberOfColumns,
        NumberOfRows,
        LayoutFormat,
        PanelName,
        PanelWidth,
        PanelFontSize
    } = panelData;

    const [isMinimized, setIsMinimized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef<HTMLDivElement>(null)
    const toastRef = useRef<Toast>(null);

    const panelItemSelector = useSelector((state: State) => state.panelItem);

    const renderLayout = (layoutFormat: string): JSX.Element => {
        switch (layoutFormat) {
            case "Chart":
            case "Timeline":
            case "Tree":
            case "Table":
                return (
                    <PanelDataComponent
                        widgetData={widgetData}
                        panelData={panelData}
                        actions={Actions}
                        panelWidth={ref.current?.clientWidth!} // p-panel-content has 2em(32px) padding
                        onLoading={(value: any)=> {
                            setIsLoading(value)
                        }}
                    />
                );
            case "Form":
                return (
                    <PanelSearchComponent
                        widgetName={widgetData.WidgetName}
                        numberOfColumns={NumberOfColumns}
                        numberOfRows={NumberOfRows}
                        panelData={panelData}
                        panelButtons={Buttons}
                    />
                );
            case "Iframe":
                return (
                    <PanelIframeComponent panelData={panelData} />
                );
            default:
                return <div />
        }
    };

    useEffect(() => {
        if (!isEmpty(panelItemSelector)) {
            let toastOptions: any = { severity: "success" };
            let returnType = 0;
            const panelName = `${widgetData.WidgetName}_${PanelName}`;

            if (panelItemSelector.isExportSuccess) toastOptions["summary"] = "Export Success";
            if (panelItemSelector.isExportError) {
                toastOptions["summary"] = "Export Error";
                toastOptions.severity = "error";
                toastOptions.icon = <></>;
            }

            if (panelItemSelector.isSuccessSubmitActionData) toastOptions["summary"] = "Action Success";
            if (panelItemSelector.isErrorSubmitActionData) {
                toastOptions["summary"] = "Action Error";
                toastOptions.severity = "error";
                toastOptions.icon = <></>;
            }

            if (panelItemSelector.isErrorGetTableData) {
                toastOptions["summary"] = "Failed to fetch data";
                toastOptions.severity = "error";
                toastOptions.icon = <></>;
            }

            if (!isEmpty(panelItemSelector?.successData)) {
                if (panelItemSelector.successData?.targetPanel === panelName) {
                    toastOptions["detail"] = panelItemSelector.successData?.message;
                    toastOptions["detail"] = panelItemSelector.successData.data?.ReturnMessage;
                    returnType = panelItemSelector.successData.data?.ReturnType;
                    if (panelItemSelector.successData.hasOwnProperty("response")) {
                        if (!isEmpty(panelItemSelector.successData.response?.ReturnMessage)) {
                            returnType = panelItemSelector.successData.response?.ReturnType;
                            toastOptions["detail"] = panelItemSelector.successData.response?.ReturnMessage;
                        }
                    }
                }
            }

            if (!isEmpty(panelItemSelector?.errorData)) {
                toastOptions.icon = <></>;
                if (panelItemSelector.errorData?.targetPanel === panelName) {
                    if (typeof panelItemSelector.errorData?.message === "string") {
                        toastOptions["detail"] = panelItemSelector.errorData.message;
                        returnType = 4;
                    } else if (typeof panelItemSelector.errorData?.message === "object") {
                        toastOptions["detail"] = panelItemSelector.errorData.message?.ReturnMessage;
                        returnType = panelItemSelector.errorData.message?.ReturnType;
                    }
                    if (panelItemSelector.errorData?.data) {
                        toastOptions["detail"] = panelItemSelector.errorData.data?.ReturnMessage;
                        returnType = panelItemSelector.errorData.data?.ReturnType;
                    }
                    if (panelItemSelector.errorData.hasOwnProperty("response") && !isEmpty(panelItemSelector.errorData.response)) {
                        returnType = panelItemSelector.errorData.response?.ReturnType;
                        if (!isEmpty(panelItemSelector.errorData.response?.ReturnMessage)) {
                            toastOptions["detail"] = panelItemSelector.errorData.response?.ReturnMessage;
                        }
                    }
                }
            }

            const severityOptions = ["", "success", "warn", "error", "error"]
            toastOptions.severity = ([0,1,2,3,4].includes(returnType)) ? severityOptions[returnType] || "" : null            

            if (toastOptions.severity === "success") toastOptions["life"] = 5000;
            if (toastOptions.severity === "warn") toastOptions["life"] = 15000;
            if (toastOptions.severity === "error") toastOptions["life"] = 30000;
            
            if (!isEmpty(toastOptions?.detail)) {
                if (toastOptions.detail === "jwt expired") {
                    localStorage.clear();
                    window.location.reload();
                }
                if (toastRef && parseInt(`${returnType}`) > 0) {
                    toastRef.current!.show(toastOptions);
                } else {
                    console.error(toastOptions.detail);
                }
            }
        }
        // eslint-disable-next-line
    }, [panelItemSelector, PanelName]);

    useEffect(() => {        
        // Minimize panel
        if (panelData?.MinimizeButton?.MinimizeOnInit) setIsMinimized(true);
    }, [panelData]);

    return (
        <section
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                //maxWidth: window.innerWidth - 350 + "px", //panelData?.PanelMaxWidth ? panelData.PanelMaxWidth : 0,
                minHeight: panelData?.PanelMinHeight ? (parseInt(panelData.PanelMinHeight.toString().replace("px", "") || '0') + (panelData.LayoutFormat === "Table" ? 168 : 0)) + "px" : 0,
            }}
        >
            {
                (!isEmpty(panelData.LoadData?.RefreshInterval) && panelData.LoadData?.RefreshInterval! > 0) ?
                <div style={{ textAlign: "right" }}>
                  {
                        isLoading ? "Loading..." : `refresh after ${panelData.LoadData?.RefreshInterval!} seconds`
                  }
                </div> : null
            }

            {
                panelData?.MinimizeButton?.Enable ? (
                    <section className="btn-minimize-wrapper">
                        <Button
                            className="p-button-secondary p-button-raised"
                            label={panelData.MinimizeButton.Label ? __t(panelData.MinimizeButton, "Label") : "Filter"}
                            icon={`pi ${isMinimized ? "pi-plus" : "pi-minus"}`}
                            iconPos="right"
                            onClick={() => setIsMinimized((isMinimized: boolean) => !isMinimized)}
                        >
                        </Button>
                    </section>
                ) : null
            }
            <div
                ref={ref}
                style={{
                    width: PanelWidth,
                    height: isMinimized ? 0 : "100%",
                    fontSize: `${PanelFontSize}px`,
                    visibility: isMinimized ? "hidden" : "visible",
                    opacity: isMinimized ? 0 : 1,
                    transition: "opacity 400ms ease 0s, visibility 400ms ease 0s, height 200ms ease 0s"
                }}
            >
                <Toast ref={toastRef} />
                {renderLayout(LayoutFormat)}
            </div>
        </section>
    );
};

export default PanelContainer;